@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --option-string-active-background: #{$default-primary-base-color};
    --option-text-selected-color: #{$default-primary-base-color};
    --product-attribute-color-border-radius: 0;
    --product-attribute-color-border-color: #{$default-primary-base-color};
    --product-attribute-text-border-radius: 3px;
    --product-attribute-text-border-color: #{$grey4};
}

[dir='ltr'] .ProductAttributeValue {
    &-String {
        --option-text-color: #{$grey3};
        font-weight: 400;
        padding: 8px 15px;
        --option-background-color: #{$white};
        border: 1px solid var(--product-attribute-text-border-color);

        &_isSelected {
            --option-text-color: var(--option-string-active-color);
            --product-attribute-text-border-color: #{$default-primary-base-color};
        }
    }

    &:hover {
        .ProductAttributeValue-String {
            --option-text-color: var(--option-string-active-color);
            --product-attribute-text-border-color: #{$default-primary-base-color};
        }
    }

    &-Text {
        label {
            &:first-of-type {
                .input-control {
                    border-color: $grey4;
                }
            }
        }

        &:hover .input-control::after,
        &:focus .input-control::after {
            --checkmark-color: #{$default-primary-base-color};
        }


        &_isSelected {
            color: var(--option-text-selected-color);

            &:hover .input-control::after,
            &:focus .input-control::after {
                --checkmark-color: #{$white};
            }
        }
    }
}
