@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --product-galery-border-color: #{$grey5};
}

.ProductGallery {
    &-Additional {
        @include desktop {
            margin-right: 18px;
        }
    }

    &-SliderWrapper {
        order: 1;
        border: 1px solid var(--product-galery-border-color);
    }

    &ThumbnailImage {
        border: 1px solid var(--product-galery-border-color);
    }
}
