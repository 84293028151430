@import '../../style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --daily-promo-price-promo-color: #{$default-primary-base-color};
    --daily-promo-cta-background: #{$default-secondary-base-color};
    --daily-promo-cta-hover-background: #{$default-secondary-dark-color};
    --daily-promo-cta-hover-border: #{$default-secondary-dark-color};
}

[dir='ltr'] .DailyPromoProduct {
    border-radius: 10px;

    &-Title {
        border-radius: 0 0 5px 5px;
    }

    &-AddToCart {
        --button-height: 48px;
        --button-hover-height: 48px;

        svg {
            display: none;
        }
    }
}
