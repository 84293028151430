@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.ProductsSlider {
    .AdvoxSlider-Heading {
        font-weight: bold;
        font-size: 22px;
        line-height: 32px;
        margin: 10px 20px;
    }
    .slick-slide > div {
        padding: 30px 0;
    }
    .slick-arrow {
        width: 40px;
        height: 40px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0992304);

        @include mobile {
            svg {
                width: 7px;
                height: 12px;
            }
            &.slick-prev {
                border-radius: 0 20px 20px 0;
                svg {
                    right: -15px;
                }
            }
            &.slick-next {
                border-radius: 20px 0 0 20px;
                svg {
                    left: 20px;
                }
            }
        }

        @include from-tablet {
            &.slick-prev {
                left: 20px;
            }
            &.slick-next {
                right: 20px;
            }
        }
        @include wider-desktop {
            &.slick-prev {
                left: 0;
            }
            &.slick-next {
                right: 0;
            }
        }
    }
}
