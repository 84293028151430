@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.AskForProduct {
    @include from-tablet {
        display: flex;
    }
    .FieldForm-Fields {
        margin-bottom: 0;

        .Field:first-of-type {
            @include from-tablet {
                margin-top: 0;
            }
        }

        textarea {
            width: 100%;
        }
        @include mobile {
            margin-bottom: 24px;
        }

        .Field_type_checkbox .Field-ErrorIcon {
            display: none;
        }
    }

    .Recaptcha {
        margin-block-start: 25px;
    }

    .Button {
        box-shadow: none;
        font-family: $font-primary;
        letter-spacing: 0.07em;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        min-width: 274px;
        margin-top: 25px;
    }
    .Field_type_checkbox {
        .input-control {
            margin-right: 15px;
            top: 2px;
        }
        .Field-CheckboxLabel {
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 13px;
            line-height: 150%;
            color: $grey-dark;
            width: 100%;
            max-width: unset;
            align-items: flex-start;
        }
    }

    .Consents {
        &-FieldWrapper {
            margin-bottom: 0;
        }

        .Field_type_checkbox {
            &.Consents-Field {
                .input-control {
                    min-width: 16px;
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .Field-CheckboxLabel {
            &:last-of-type {
                margin-bottom: 0;
            }

            & > span {
                display: block;
            }

            p {
                font-family: $font-secondary;
                font-weight: normal;
                font-size: 13px;
                line-height: 150%;
                color: $grey-dark;
            }

            .Consents-Label_isRequired {
                padding-left: 0;
            }
        }

        &-Label_isRequired {
            color: $default-primary-base-color;
        }
    }

    &-CmsBlock {
        p {
            font-family: $font-secondary;
            font-weight: normal;
            font-size: 13px;
            line-height: 150%;
            color: $grey-dark;
        }

        .CmsBlock-Wrapper {
            margin-bottom: 20px;

            @include mobile {
                margin-top: 20px;
            }
        }
    }

    &-ImageContainer {
        display: flex;
        align-items: flex-start;
        @include from-tablet {
            min-width: 222px;
            margin-right: 26px;
        }
        @include desktop {
            min-width: 252px;
        }
        .Image {
            min-width: 99px;
            max-width: 99px;
            height: 99px;

            @include from-tablet {
                min-width: 222px;
                max-width: 222px;
                height: 222px;
            }

            @include desktop {
                min-width: 252px;
                max-width: 252px;
                height: 252px;
            }
        }

        .AskForProduct-ProductName {
            margin-left: 16px;
            margin-top: 10px;
        }
    }
    &-ProductName {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        letter-spacing: 0.07em;
        color: $blue50;
    }

    &-FormContainer {
        max-width: 536px;
        width: 100%;
    }
}
