@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

[dir='ltr'] .ProductCompareButton {
    svg {
        width: 20px;
        path {
            stroke: $default-primary-base-color;
        }
    }

    .Button:hover {
        background: $default-primary-base-color;
        svg path {
            fill: $white;
            stroke: $white;
        }
    }
}
