@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --daily-promo-title-font: #{$font-primary};
    --daily-promo-title-background: #{$default-primary-base-color};
    --daily-promo-title-color: #{$white};
    --daily-promo-content-background: #{$white};
    --daily-promo-name-font: #{$font-secondary};
    --daily-promo-name-color: #{$black};
    --daily-promo-price-font: #{$font-secondary};
    --daily-promo-price-old-color: #{$grey-dark};
    --daily-promo-price-promo-color: #{$red70};
    --daily-promo-counter-tile-background: #{$grey-light};
    --daily-promo-counter-tile-color: #484848;
    --daily-promo-counter-text-color: #{$grey-dark};
    --daily-promo-counter-text-font: #{$font-secondary};
    --daily-promo-cta-color: #{$white};
    --daily-promo-cta-background: #{$default-tertiary-base-color};
    --daily-promo-cta-hover-background: #{$default-primary-base-color};
    --daily-promo-cta-hover-border: #{$default-primary-base-color};
    --daily-promo-cta-hover-color: #{$white};
    --daily-promo-img-background: #{$white};
    --daily-promo-max-width: 534px;
}

[dir='ltr'] .DailyPromoProduct {
    border: 4px solid $default-primary-base-color;

    @include mobileAndTablet {
        width: calc(100% - 40px);
        max-width: 576px;
    }

    @include tablet {
        margin: 0 auto;
    }

    @include mobile {
        margin: 0 20px;
    }

    &-CountdownItem > div {
        width: 40px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-Content {
        @include desktop {
            max-width: calc(var(--daily-promo-max-width) - 8px);
            min-width: calc(var(--daily-promo-max-width) - 8px);
        }
    }
    &-ShoppingList {
        color: $black;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        width: 100%;
        cursor: pointer;
        margin: 10px 0 15px;
        @include desktop {
            margin: 15px 0;
        }
        svg {
            margin-right: 11px;
        }
    }
    &-Title {
        border-radius: 0;
        text-align: left;
        padding-left: 24px;
        height: 56px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        line-height: 28px;

        @include from-tablet {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
        }
    }
    &-ImgWrapper {
        border-radius: 0;
        border: 1px solid $grey-light;
        margin-bottom: 15px;
        @include from-tablet {
            margin: 58px 24px 0 0;
        }
    }
    &-Countdown {
        margin: 0 0 25px;
    }
    &-Sku {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $grey-dark;
        margin-bottom: 8px;
    }
    &-Name {
        font-size: 16px;
        line-height: 24px;
    }
    &-PriceItem {
        display: flex;
        align-items: baseline;
        &_withTax {
            @include mobileAndTablet {
                display: none;
            }
            .DailyPromoProduct-Price_promoPrice,
            .DailyPromoProduct-Price_oldPrice {
                margin-top: 0;
            }
            .DailyPromoProduct-Price_promoPrice {
                color: $black;
                font-size: 16px;
                line-height: 24px;
                font-weight: normal;
                @include mobile {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
    &-PriceTypeLabel {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: $grey-dark;
        margin-right: 4px;
        @include mobileAndTablet {
            display: none;
        }
    }
    &-PriceWrapper {
        margin: 0;
    }
    &-Price {
        font-family: var(--daily-promo-price-font);
        font-weight: bold;
        letter-spacing: -0.2px;
        color: var(--daily-promo-price-promo-color);
        margin-top: 10px;
        margin-bottom: 0;
        @include from-tablet {
            display: block;
        }
    }
    &-Price_oldPrice {
        color: var(--daily-promo-price-old-color);
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;

        @include mobile {
            font-size: 16px;
            line-height: 24px;
        }

        @include from-tablet {
            margin-left: 9px;
        }
    }
    &-Price_promoPrice {
        font-size: 22px;
        line-height: 32px;
        @include mobile {
            font-size: 22px;
            order: unset;
            line-height: 32px;
        }
    }
    &-AddToCartWrapper {
        display: flex;
        align-items: center;

        .Button.AddToCart {
            --button-height: 48px;
            --button-hover-height: 48px;
            min-width: unset;
        }
        .ProductActions-Qty.Field_type_number {
            @include qty-input;
            margin: 0 8px 0 0;

            input[type='number'] {
                margin: 0;
                height: 48px;
            }
            button {
                display: none;
            }
        }
    }
}
