@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.ProductPage {
    max-width: var(--content-wrapper-width);
    margin: 0 auto;
    padding-inline: var(--content-padding);

    &-Wrapper {
        display: block;

        @include tablet {
            padding-left: 0;
            padding-right: 0;
        }

        @include desktop {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }

        @include wide-desktop {
            display: grid;
            padding-bottom: 22px;
        }
    }
}
