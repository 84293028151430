@import '../../style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

[dir='ltr'] .ProductActions {
    &-AddToCartWrapper .ProductActions-AddToCart,
    &-AddToCartMobile .ProductActions-AddToCart {
        &:before {
            background: $white;
        }
    }
    .ProductWishlistButton,
    .ProductCompareButton {
        button {
            svg path {
                stroke: $orange50;
                fill: $orange50;
            }
            &:hover {
                background: $orange50;
                svg path {
                    stroke: $white;
                    fill: $white;
                }
            }
        }
    }
}
