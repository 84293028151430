@import '../../style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

:root {
    --option-string-active-background: #{$default-secondary-base-color};
    --option-text-selected-color: #{$default-secondary-base-color};
    --product-attribute-color-border-radius: 0;
    --product-attribute-color-border-color: #{$default-secondary-base-color};
    --product-attribute-text-border-radius: 3px;
    --product-attribute-text-border-color: #{$grey4};
}

[dir='ltr'] .ProductAttributeValue {
    &-Text {
        &:hover .input-control::after,
        &:focus .input-control::after {
            --checkmark-color: #{$default-secondary-base-color};
        }
    }
}
