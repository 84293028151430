@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --product-configurable-dropdown-width: 200px;
    --product-configurable-dropdown-color: #{$blue50};
    --product-configurable-dropdown-arrow-color: #{$blue50};
    --product-configurable-dropdown-border-color: #{$grey5};
    --product-configurable-dropdown-hover-color: #{$blue50};
    --product-configurable-dropdown-radius: 5px;
    --category-sort-mobile-width: 154px;
    --category-sort-desktop-width: 193px;
}

.ProductConfigurableAttributeDropdown {
    width: var(--product-configurable-dropdown-width);

    @include mobile {
        --product-configurable-dropdown-width: 100%;
    }

    &.Field_type_select {
        .FieldSelect {
            min-width: auto;
            &:hover,
            &:focus {
                .FieldSelect-Select {
                    color: var(--product-configurable-dropdown-color);
                    border-color: var(--product-configurable-dropdown-border-color);
                }
                .ChevronIcon {
                    fill: var(--product-configurable-dropdown-color);
                }
            }

            &-Clickable {
                .ChevronIcon {
                    right: 24px;
                    fill: var(--product-configurable-dropdown-arrow-color);
                    path {
                        stroke: var(--product-configurable-dropdown-arrow-color);
                    }
                }
            }

            &-Select {
                font-size: 13px;
                font-weight: 700;
                padding: 8px 20px;
                letter-spacing: 0.5px;
                font-family: $font-primary;
                border-radius: var(--product-configurable-dropdown-radius);
                border: 1px solid var(--product-configurable-dropdown-border-color);
                color: var(--product-configurable-dropdown-color);

                &_isExpanded {
                    border-block-end: 0;
                    font-weight: 700;
                    border-radius: var(--product-configurable-dropdown-radius)
                        var(--product-configurable-dropdown-radius) 0 0;
                }
            }

            &-Options {
                z-index: -1;
                border-radius: 0 0 var(--product-configurable-dropdown-radius)
                    var(--product-configurable-dropdown-radius);
                top: 39px;
                &_isExpanded {
                    z-index: 9;
                    border-color: var(--product-configurable-dropdown-border-color);
                }

                @include mobile {
                    width: 100%;
                }
            }

            &-Option {
                &,
                &:first-of-type {
                    padding: 0 18px 7px;
                    font-size: 13px;
                    font-family: $font-primary;
                    @include from-tablet {
                        font-size: 13px;
                    }
                    @include desktop {
                        padding: 0 20px 7px;
                    }
                    &:hover {
                        color: var(--product-configurable-dropdown-hover-color);
                    }
                }
                &:first-of-type {
                    margin-top: 7px;
                }
                &:last-of-type {
                    margin-bottom: 7px;
                }
            }
        }
    }
}
