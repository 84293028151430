@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.ProductPrice {
    &-PriceTypeLabel {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: $grey-dark;
        margin-right: 4px;
    }
    &-PriceValue,
    &-SubPrice {
        font-size: 15px;
        line-height: 20px;
        color: $black;
    }
    &-PriceValue {
        font-weight: normal;
    }
    &-SubPrice {
        font-weight: bold;
        margin: 0;
    }
}
