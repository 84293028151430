@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --review-rating-star-color: #{$default-secondary-base-color};
}

.ProductReviewRating {
    margin: 16px 0 28px;
    display: flex;
    align-items: center;
    svg {
        margin: 0 -4.5px 0 0;
        width: 26px;
        height: 25px;
        cursor: default;
        position: relative;
        top: 4px;
    }

    svg,
    svg path {
        fill: var(--review-rating-star-color);
    }

    &-Counter {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: $black;
        margin-left: 22px;

        &_isCounterInBrackets {
            color: $grey-dark;
            margin-left: 7px;
        }
    }
}
