@import '../../style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../style/abstract/button';

.ProductDetails {
    &-NavigationItem {
        a {
            &.active {
                &:before {
                    background: $default-secondary-base-color;
                }
            }
        }
    }
}
